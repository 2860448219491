<template>
  <div id="ugrr">
    <!-- Hidden stuff for crawlers -->
    <!-- <h1 class="hidden-preview">UGRR and Antislavery Activists</h1>
    <p class="hidden-preview">Underground Railroad and Antislavery Activists in Chautauqua County New York 1836 - 1863</p>
    <img class="hidden-preview" src="https://firebasestorage.googleapis.com/v0/b/the-mapping-service.appspot.com/o/projects%2Fugrr%2FFD8B5DE5-27D4-4FFC-B884-C7F06337D045.png?alt=media&token=1b22c304-0d1d-49bb-aab2-d60e0f206457" /> -->
    <b-navbar id="ugrrNav" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand>UGRR and Antislavery Activists</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item @click="showAbout()"><i class="fas fa-info-circle"></i> About</b-nav-item>
            <b-nav-item
              href="https://chqugrr.com/sources"
              target="_blank"
            >
              <i class="fas fa-book"></i> Sources
            </b-nav-item>
            <b-nav-item @click="showList()"><i class="fas fa-user-friends"></i> People</b-nav-item>
            <b-nav-item href="https://youtu.be/khblPVcGMlM" target="_blank"><i class="fas fa-video"></i> Watch The Film</b-nav-item>
            <b-nav-item-dropdown text="Reference Maps" right>
              <b-dropdown-item
                href="https://www.loc.gov/resource/g3803c.la000480/"
                target="_blank"
              >1854 Chaut Co NY Map</b-dropdown-item>
              <b-dropdown-item
                href="https://www.loc.gov/resource/g3803e.la000494/"
                target="_blank"
              >1855 Erie Co NY Map</b-dropdown-item>
              <b-dropdown-item
                href="https://www.loc.gov/item/2012592169/"
                target="_blank"
              >1855 Erie Co PA Map</b-dropdown-item>
              <b-dropdown-item
                href="https://www.loc.gov/resource/g3803c.la000477a/"
                target="_blank"
              >1856 Catt Co NY Map</b-dropdown-item>
              <b-dropdown-item
                href="http://www.historicmapworks.com/Atlas/US/7147/Chautauqua+County+1867/"
                target="_blank"
              >1867 Atlas Chaut Co NY</b-dropdown-item>
              <b-dropdown-item
                href="http://www.historicmapworks.com/Atlas/US/9707/Chautauqua+County+1881/"
                target="_blank"
              >1881 Atlas Chaut Co NY</b-dropdown-item>
              <b-dropdown-item
                href="https://chautauqua.nygenweb.net/HISTORY/ChautauquaCountyWallMap1916/ChautauquaMap1916.html"
                target="_blank"
              >1916 Chaut Co NY Map</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item href="https://orbitist.com/contact" target="_blank"><i class="fas fa-paper-plane"></i> Contact</b-nav-item>
            <b-nav-item href="/gologin"><i class="fas fa-sign-in-alt"></i> Login</b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="filters">
      <div v-if="collapsed">
        <span class="float-right clickable mr-2"><i @click="collapsed = false" class="fas fa-angle-up collapse-button"></i></span>
        <h2 @click="collapsed = false" class="clickable mb-0 text-center"><strong>Filtered Views</strong></h2>
      </div><!-- /v-if=collapsed -->
      <div v-else>
        <span class="float-right clickable mr-2"><i @click="collapsed = true" class="fas fa-angle-down collapse-button"></i></span>
        <h2 class="mb-0 text-center"><strong>Filtered Views</strong></h2>
        <p @click="filter('UGRR')">UGRR</p>
        <p @click="filter('Abolitionist')">Abolitionist</p>
        <p @click="filter('Persons of Color')">Persons of Color</p>
        <p @click="filter('all')">All</p>
      </div><!-- /v-else -->
    </div><!-- /#filters -->
    <div id="map"></div><!-- /#map -->
    <div id="map-spinner" v-if="loading">
      <RingLoader
        :color="'white'"
      ></RingLoader>
    </div><!-- /#map-spinner -->
    <DataPointDetails v-if="$store.state.drawer.dataPointDetails.show" />
    <ListDrawer v-if="$store.state.drawer.listDrawer.show" />
    <DatasetDetails v-if="$store.state.drawer.datasetDetails.show" />
  </div><!-- /#ugrr -->
</template>

<script>
import RingLoader from 'vue-spinner/src/GridLoader'
import DataPointDetails from './DataPointDetails'
import ListDrawer from './ListDrawer'
import DatasetDetails from './DatasetDetails'
import axios from 'axios'

export default {
  data () {
    return {
      endpoint: 'https://us-central1-the-mapping-service.cloudfunctions.net/api/geojson/sTyJjtUItOa0awYD1zls',
      mapboxToken: 'pk.eyJ1IjoidGhlbWFwcGluZ3NlcnZpY2UiLCJhIjoiY2t1aWJ2c3hsMWkwYzJ1cGhyNjRndWhzZiJ9.Dm5XwBzvVMaXNK0BpiQLHA',
      rawGeoJson: {},
      loading: true,
      collapsed: false
    }
  },
  computed: {
    mapObject: {
      get () {
        return this.$store.state.mapObject
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'mapObject', value: value })
      }
    },
    datasetGeoJson: {
      get () {
        return this.$store.state.datasetGeoJson
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'datasetGeoJson', value: value })
      }
    }
  },
  components: {
    RingLoader,
    DataPointDetails,
    ListDrawer,
    DatasetDetails
  },
  mounted () {
    this.initData()
  },
  methods: {
    filter (value) {
      // filter down datasetGeoJson
      this.datasetGeoJson = {
        type: 'FeatureCollection',
        features: []
      }
      this.rawGeoJson.features.forEach((feature, i) => {
        let pushFeature = false
        if (value === 'all') {
          pushFeature = true
        } else if (feature.properties['Operative type']) {
          feature.properties['Operative type'].forEach((type, i) => {
            if (type === value) {
              pushFeature = true
            }
          })
        }
        if (pushFeature) {
          this.datasetGeoJson.features.push(feature)
        }
      })
      console.log(this.datasetGeoJson)
      this.renderViz()
    },
    showAbout () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'datasetDetails')
      }, 300)
    },
    showList () {
      this.$store.commit('hideAllDrawers')
      setTimeout(() => {
        this.$store.commit('showDrawer', 'listDrawer')
      }, 300)
    },
    clickPoint (e) {
      // HIGHLIGHT THE DATAPOINT
      const features = this.mapObject.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] })
      if (!features.length) { return }
      this.$store.commit('hideAllDrawers')
      if (typeof this.mapObject.getLayer('selectedDataPoint') !== 'undefined') {
        this.mapObject.removeLayer('selectedDataPoint')
        this.mapObject.removeLayer('selectedDataPointHighlight')
        this.mapObject.removeSource('selectedDataPoint')
      }
      const feature = features[0]
      // SOURCE FOR THE SELECTED POINT
      this.mapObject.addSource('selectedDataPoint', {
        type: 'geojson',
        data: feature.toJSON()
      })
      // DOT HIGHLIGHT
      this.mapObject.addLayer({
        id: 'selectedDataPointHighlight',
        type: 'circle',
        source: 'selectedDataPoint',
        paint: {
          'circle-radius': 8,
          'circle-color': 'white'
        }
      })
      // RED DOT
      this.mapObject.addLayer({
        id: 'selectedDataPoint',
        type: 'circle',
        source: 'selectedDataPoint',
        paint: {
          'circle-radius': 6,
          'circle-color': 'red'
        }
      })
      const dataPoint = features[0].properties
      if (this.rawGeoJson.fields) {
        // DYNAMIC
        this.$store.commit('setStateProperty', {
          property: 'dataset',
          value: {
            dynamic: true
          }
        })
        this.$store.commit('setStateProperty', {
          property: 'collector',
          value: {
            fields: this.rawGeoJson.fields
          }
        })
      } else {
        // STATIC
        dataPoint.fields = Object.keys(dataPoint)
      }
      setTimeout(() => {
        this.$store.commit('setStateProperty', { property: 'dataPoint', value: dataPoint })
        this.$store.commit('showDrawer', 'dataPointDetails')
      }, 300)
    },
    clickCluster (e) {
      const self = this
      const features = this.mapObject.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      })
      const clusterId = features[0].properties.cluster_id
      this.mapObject.getSource('ugrrDataSource').getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return
          self.mapObject.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          })
        }
      )
    },
    renderViz () {
      const self = this
      if (this.mapObject.getLayer('clusters')) {
        this.mapObject.removeLayer('clusters')
        this.mapObject.removeLayer('cluster-count')
        this.mapObject.removeLayer('unclustered-point')
      }

      if (this.mapObject.getSource('ugrrDataSource')) {
        this.mapObject.removeSource('ugrrDataSource')
      }

      this.mapObject.addSource('ugrrDataSource', {
        type: 'geojson',
        data: this.datasetGeoJson,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      })

      this.mapObject.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'ugrrDataSource',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      })

      this.mapObject.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'ugrrDataSource',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      })

      this.mapObject.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'ugrrDataSource',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      })

      this.mapObject.on('click', 'clusters', self.clickCluster)

      this.mapObject.on('click', 'unclustered-point', self.clickPoint)

      this.mapObject.on('mouseenter', 'clusters', function () {
        self.mapObject.getCanvas().style.cursor = 'pointer'
      })

      this.mapObject.on('mouseleave', 'clusters', function () {
        self.mapObject.getCanvas().style.cursor = ''
      })

      this.mapObject.on('mouseenter', 'unclustered-point', function () {
        self.mapObject.getCanvas().style.cursor = 'pointer'
      })

      this.mapObject.on('mouseleave', 'unclustered-point', function () {
        self.mapObject.getCanvas().style.cursor = ''
      })

      this.loading = false
    },
    filterData () {
      console.log('Filter')
    },
    initData () {
      axios.get(this.endpoint).then(response => {
        this.rawGeoJson = response.data
        this.datasetGeoJson = response.data
        this.initMap()
      })
    },
    initMap () {
      mapboxgl.accessToken = this.mapboxToken /* eslint-disable-line */
      this.mapObject = new mapboxgl.Map({ /* eslint-disable-line */
        container: 'map',
        style: 'mapbox://styles/themappingservice/ckuibhxr005nf18pk5yl9dnay',
        center: [-79.2746, 42.2590],
        zoom: 8
      })
      this.mapObject.on('load', () => {
        this.mapObject.addControl(new mapboxgl.NavigationControl()) /* eslint-disable-line */
        this.renderViz()
      })
    }
  }
}
</script>

<style scoped>
/* #ugrrNav {
  position: fixed;
  width: 100%;
  z-index: 10;
} */

#map {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
}

#map-spinner {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
  z-index: 5;
  background-color: rgba(0,0,0,0.5);
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

#filters {
  position: absolute;
  bottom: 30px;
  right: 10px;
  z-index: 2;
  background-color: #333;
  padding: 2px 5px;
  border-radius: 4px;
  color: #eee;
  min-width: 180px;
}
#filters p {
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: sans-serif;
  margin: 4px;
  padding: 4px;
  border-radius: 4px;
  background-color: #555;
}
#filters p:hover {
  background-color: #333; color: #eee;
}
#filters h2 {
  font-size: 18px;
  margin: 4px;
  font-family: sans-serif;

}
</style>
