<template>
  <div class="media-input" v-if="thumbsReady">
    <!-- PREVIEW IMAGES -->
    <div
      v-for="(thumb, i) in thumbs"
      v-bind:key="i"
      class="preview-image-container"
    >
      <div @click="deleteMedia(thumb, i)" class="preview-image-delete clickable">
        <i class="far fa-times"></i>
      </div><!-- /.preview-image-delete -->
      <img :src="thumb.url" width="100%">
      <p><small>{{ thumb.path }}</small></p>
      <b-form-input
        v-model="thumb.caption"
        placeholder="Caption goes here."
        @input="updateCaption(i)"
        class="mt-2 mb-2"
      ></b-form-input>

      <b-btn
        variant="light"
        @click="move(i, 'up')"
        size="sm"
        v-if="i > 0"
        class="mr-1"
      ><i class="fas fa-arrow-up"></i> Move Up</b-btn>

      <b-btn
        variant="light"
        @click="move(i, 'down')"
        size="sm"
        v-if="i + 1 < thumbs.length"
      ><i class="fas fa-arrow-down"></i> Move Down</b-btn>

    </div><!-- /.preview-image-container -->

    <!-- FILES INPUT -->
    <input
      type="file"
      class="form-control-file"
      multiple
      accept="image/x-png,image/gif,image/jpeg"
      @change="onFileSelected"
      id="mediaInput"
    />
    <!-- PROGRESS BAR -->
    <div class="progress mt-2" style="height: 4px;">
      <div class="progress-bar" id="progressBar" role="progressbar" style="width: 0%;" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div><!-- /.media-input -->
</template>

<script>
import firebase from 'firebase'

export default {
  data () {
    return {
      mediaPayload: [],
      thumbs: [],
      thumbsReady: false
    }
  },
  props: ['mediaObject', 'blockUpload'],
  created () {
    this.mediaPayload = []
    if (this.mediaObject) {
      this.mediaPayload = this.mediaObject.filter(item => item && item.small)
      const imagePromises = []
      const self = this
      // Create a temporary array to maintain order
      const orderedThumbs = new Array(this.mediaPayload.length)
      this.mediaPayload.forEach((mediaItem, index) => {
        const prom = self.getImageUrl(mediaItem, index).then(() => {
          // Place each thumb in its correct position
          orderedThumbs[index] = self.thumbs[self.thumbs.length - 1]
        }).catch(() => {
          // Silently ignore failed image loads
          return null
        })
        imagePromises.push(prom)
      })

      Promise.all(imagePromises).then(() => {
        // Filter out any null entries and set the thumbs array
        self.thumbs = orderedThumbs.filter(thumb => thumb)
        self.thumbsReady = true
      })
    } else {
      this.thumbsReady = true
    }
  },
  methods: {
    move (index, option) {
      let toIndex
      if (option === 'up') {
        toIndex = index - 1
      } else if (option === 'down') {
        toIndex = index + 1
      }
      const element = this.thumbs.splice(index, 1)[0]
      this.thumbs.splice(toIndex, 0, element)
      this.syncArrays()
    },
    syncArrays () {
      // Update mediaPayload to match thumbs order and content
      this.mediaPayload = this.thumbs.map(thumb => ({
        path: thumb.path,
        small: thumb.small,
        medium: thumb.medium,
        large: thumb.large,
        caption: thumb.caption
      }))
      this.$emit('mediaDelivery', this.mediaPayload)
    },
    getImageUrl (mediaItem, index) {
      const self = this
      return firebase.storage().ref(mediaItem.small).getDownloadURL().then(url => {
        self.thumbs.push({
          url: url,
          path: mediaItem.path,
          small: mediaItem.small,
          medium: mediaItem.medium,
          large: mediaItem.large,
          caption: mediaItem.caption,
          index: index
        })
      })
    },
    updateCaption (index) {
      this.syncArrays()
    },
    onFileSelected (e) {
      const self = this
      function getFormattedTime () {
        var today = new Date()
        var y = today.getFullYear()
        var m = today.getMonth() + 1
        var d = today.getDate()
        return y + '-' + m + '-' + d + '-'
      }
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }

      for (var i = 0; i < e.target.files.length; i++) {
        const mediaId = getFormattedTime() + s4()
        // Get the image item
        const file = e.target.files[i]
        const fileName = mediaId + file.name
        const smallFileName = fileName.replace(/(\.[\w\d_-]+)$/i, '_500x500$1')
        const mediumFileName = fileName.replace(/(\.[\w\d_-]+)$/i, '_1000x1000$1')
        const largeFileName = fileName.replace(/(\.[\w\d_-]+)$/i, '_2000x2000$1')
        // Cunstruct the path and storage reference for Firebase
        let mediaPath = 'datasets/dynamic/' + this.$store.state.collector.datasetId + '/images/' + fileName
        let smallMediaPath = 'datasets/dynamic/' + this.$store.state.collector.datasetId + '/images/thumbs/' + smallFileName
        let mediumMediaPath = 'datasets/dynamic/' + this.$store.state.collector.datasetId + '/images/thumbs/' + mediumFileName
        let largeMediaPath = 'datasets/dynamic/' + this.$store.state.collector.datasetId + '/images/thumbs/' + largeFileName
        if (this.blockUpload) {
          mediaPath = 'atlases/' + this.$store.state.atlas.id + '/images/' + fileName
          smallMediaPath = 'atlases/' + this.$store.state.atlas.id + '/images/thumbs/' + smallFileName
          mediumMediaPath = 'atlases/' + this.$store.state.atlas.id + '/images/thumbs/' + mediumFileName
          largeMediaPath = 'atlases/' + this.$store.state.atlas.id + '/images/thumbs/' + largeFileName
        }
        this.mediaPayload.push({
          path: mediaPath,
          small: smallMediaPath,
          medium: mediumMediaPath,
          large: largeMediaPath,
          caption: ''
        })
        // Set the Firebase storage reference for this media item
        const storageRef = firebase.storage().ref(mediaPath)
        // Upload the file to Firebase with task variable
        const task = storageRef.put(file)
        // Listen for state changes as media item is being uploaded
        task.on('state_changed', function progress (snapshot) {
          // Set percentage of the progress bat
          // todo - Make this progress bar smarter to take account of all media items being uploaded at the same time.
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '%'
          document.getElementById('progressBar').style.width = percentage
        },
        function error (err) {
          console.log('errors: ' + err)
        },
        function complete () {
          document.getElementById('progressBar').style.width = 0
          firebase.storage().ref(mediaPath).getDownloadURL().then(url => {
            self.thumbs.push({
              url: url,
              path: mediaPath,
              small: smallMediaPath,
              medium: mediumMediaPath,
              large: largeMediaPath,
              caption: ''
            })
          })
        })
      }
      setTimeout(function () {
        self.$emit('mediaDelivery', self.mediaPayload)
      }, 2000)
    },
    deleteMedia (media, i) {
      if (!this.$store.state.display.editMode) {
        // firebase.storage().ref(media.path).delete()
        // firebase.storage().ref(media.small).delete()
        // firebase.storage().ref(media.medium).delete()
        // firebase.storage().ref(media.large).delete()
      }
      this.thumbs.splice(i, 1)
      this.mediaPayload.splice(i, 1)
      this.$emit('mediaDelivery', this.mediaPayload)
    }
  }
}
</script>

<style scoped>
.preview-image-container {
  position: relative;
  display: inline-block;
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  width: 100%;
  text-align: center;
}
.preview-image-delete {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #dc3545;
  border-radius: 50px;
  padding: 0px 7px
}
</style>
